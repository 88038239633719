
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins } from "vue-property-decorator";
import { NavBar, Row, Col, Icon, Image, List, Cell, Button, Toast } from "vant";
import goodList from "./goodList.vue";
import { NavigationGuardNext, Route } from "vue-router";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Image.name]: Image,
    [List.name]: List,
    [Cell.name]: Cell,
    [Button.name]: Button,
    goodList,
  },
})
export default class ExchangeGood extends Mixins(Mixin) {
  public RecordList: Array<member.GetCommodityInfoResponse> = [];
  public activityId = "";
  public activityInfo: member.GetExchangeGiftsListResponse = {
    activityOverdue: false,
    commodityInfoResponseList: [],
    expendNum: 0,
    finishPrintingNumber: 0,
    finishTaskNumber: 0,
    printingNumber: 0,
    taskAllFinishFlag: false,
    taskNumber: 0,
    usableNum: 0,
  };
  fromRouteName = ""; // 路由标识 1表示进入该页面的入口是活动列表页 2表示进入该页面的入口是活动详情页
  created(): void {
    window.document.title = "兑换好礼";
    if (
      this.ChannelModule.channel === "plat_ios" ||
      this.ChannelModule.channel === "plat_android"
    ) {
      this.jsBridge("isShowShare", "0");
    }
    let activityId = this.$route.query.activityId;
    this.activityId = activityId ? String(activityId) : "";
    this.getRecords();
  }
  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext): void {
    next((vm) => {
      (vm as ExchangeGood).fromRouteName = from.name || "";
    });
  }
  onClickLeft(): void {
    this.$router.go(-1);
  }
  getRecords(): void {
    // 接口位置
    this.$api.memberApi.printing.exchangeGiftsList(
      {
        activityId: this.activityId,
      },
      ({ data }) => {
        this.RecordList = data.commodityInfoResponseList!;
        this.activityInfo = data;
      }
    );
  }
  gotoTask(): void {
    if (this.fromRouteName === "printActivity") {
      this.$router.push(`/print-activity?id=${this.activityId}`);
    } else {
      this.$router.go(-1);
    }
  }
}
